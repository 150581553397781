.videoFooter {
    position: relative;
    color: white;
    bottom: 180px;
    margin-left: 40px;
    display: flex;
}

.videoFooter__text {
    flex: 1;
}

.videoFooter__text > p {
    font-size: 15px;
    padding-bottom: 20px;
  }
  
  .videoFooter__text > h3 {
    padding-bottom: 20px;
  }

 
.videoFooter__icon {
    position: absolute;
}


.videoFooter__ticker h1 {
    padding-top: 7px;
}
.videoFooter__ticker > .ticker {
    height: fit-content;
    margin-left: 30px;
    width: 60%;
}

.videoFooter__record {
    height: 50px;
    filter: invert(1);
    animation: spinTheRecord infinite 5s linear;
    position: absolute;
    bottom: 0;
    right: 20px;
}

@keyframes spinTheRecord {
    from {
        transform: rotate(0deg);
    }
    to {
        transform: rotate(360deg);
    }
}