.videoSideBar {
    position: absolute;
    bottom: 20%;
    right: 5px;
    color: white;
}

.videoSideBar__button {
    padding: 20px;
    text-align: center;
}