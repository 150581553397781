html {
  scroll-snap-type: y mandatory;
}

.app {
  background-color: black;
  height: 100vh;
  display: grid;
  place-items: center;
}

.app__videos {
  position: relative;
  height: 100%;
  overflow: scroll;
  width:100%;
  max-width: 400px;
  scroll-snap-type: y mandatory;
}

@media (orientation: landscape)  {
  .app__videos {
    border-radius: 20px;
    width:80%;
  }
}

/* HIDE SCROLL-BAR STARTS */

/* Hide scrollbar for Chrome, Safari and Opera */
.app__videos::-webkit-scrollbar {
  display: none;
}

/* Hide scrollbar for IE, Edge and Firefox */
.app__videos {
  -ms-overflow-style: none; /* IE and Edge */
  scrollbar-width: none; /* Firefox */
}

/* HIDE SCROLL-BAR ENDS */
